



































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller, {
  PODDriver,
} from '@/app/ui/controllers/PODTrackerController'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import {
  LocalStorage,
  POD_TRACKER_DRIVER_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import {
  POD,
  Geolocation as PODGeolocation,
  PODDestination,
} from '@/domain/entities/PODTracker'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import { Dictionary } from 'vue-router/types/router'

interface Parameter {
  page: number
  perPage: number
  q?: string
  packageId?: string
  sortBy?: string
  startDate?: string
  endDate?: string
  podStatus?: string
}

interface Dropdown {
  label: string
  value: string
}

@Component({
  components: {
    Button,
    LoadingOverlay,
    TextInput,
    DateTimePicker,
    DropdownSelect,
    DataTable,
    PaginationNav,
    CheckBoxCheckedIcon,
    Modal,
  },
})
export default class SttPODListPage extends Vue {
  controller = controller

  parameters: Parameter = {
    page: 1,
    perPage: POD_TRACKER_DRIVER_PAGINATION,
  }

  exportSuccessModal = false

  headers = [
    'No.',
    'STT POD',
    'Nama Kurir',
    'Tanggal',
    'Lokasi Aktual POD',
    'Lokasi Tujuan POD',
    'Fiktif',
    '',
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage:
          Utils.alwaysNumber(queries.perPage) || POD_TRACKER_DRIVER_PAGINATION,
        q: queries.q || undefined,
        packageId: queries.packageId || undefined,
        sortBy: queries.sortBy || 'NEWEST',
        startDate: queries.startDate || dayjs().format('YYYY-MM-DD'),
        endDate: queries.endDate || dayjs().format('YYYY-MM-DD'),
        podStatus: queries.podStatus || undefined,
      }
    }

    this.fetchPODList()
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  get driverDataTable(): Array<
    string | number | boolean | POD | PODGeolocation | PODDestination
  >[] {
    return controller.podData.map(pod => [
      '',
      pod.packageId as string,
      (this.isPODByCourier
        ? this.driverPOD.courierName
        : pod.courierName) as string,
      dayjs(pod.podDate as string)
        .locale(dayjsID)
        .format('DD MMM YYYY'),
      pod.podActual as PODGeolocation,
      pod.podDestination as PODDestination,
      pod.isPodValid as boolean,
      pod,
    ])
  }

  get params(): Record<string, string | number> {
    return {
      ...this.parameters,
    }
  }

  private search = Utils.debounce(() => {
    this.refetchData()
  }, 500)

  get sortByOptions(): Dropdown[] {
    return [
      {
        value: 'NEWEST',
        label: 'Terbaru',
      },
      {
        value: 'OLDEST',
        label: 'Terlama',
      },
    ]
  }

  get selectedSort(): Dropdown | null {
    if (!this.parameters.sortBy) return null

    return this.sortByOptions.find(
      type => type.value === this.parameters.sortBy
    ) as Dropdown
  }

  set selectedSort(type: Dropdown | null) {
    this.parameters.sortBy = type?.value
  }

  get podStatusOptions(): Dropdown[] {
    return controller.constant.podStatus
  }

  get selectedStatus(): Dropdown | null {
    if (!this.parameters.podStatus) return null

    return this.podStatusOptions.find(
      type => type.value === this.parameters.podStatus
    ) as Dropdown
  }

  set selectedStatus(type: Dropdown | null) {
    this.parameters.podStatus = type?.value
  }

  get driverPOD(): PODDriver {
    return controller.podDriver
  }

  get isPODByCourier(): boolean {
    return this.$route.name === 'SttPODListByCourier'
  }

  get searchPlaceholder(): string {
    if (this.isPODByCourier) {
      return 'STT'
    }

    return 'Nama, STT'
  }

  get searchData(): string | undefined {
    if (this.isPODByCourier) {
      return this.parameters.packageId
    }

    return this.parameters.q
  }

  set searchData(search: string | undefined) {
    if (this.isPODByCourier) {
      this.parameters.packageId = search

      return
    }

    this.parameters.q = search
  }

  private refetchData() {
    this.fetchPODList(true)
  }

  private changeDate() {
    const dateFrom = dayjs(this.parameters.startDate).startOf('day')
    const dateTo = dayjs(this.parameters.endDate).endOf('day')

    if (dateTo.diff(dateFrom, 'days') > 31) {
      this.$notify({
        title: 'Error',
        text: 'Max range date is 31 days',
        type: 'error',
        duration: 5000,
      })

      return
    }

    this.refetchData()
  }

  private formatParams(parameters: Parameter) {
    let endDate = undefined
    if (parameters.endDate) {
      endDate = dayjs(this.parameters.endDate)
      if (!endDate.isSame(new Date(), 'day')) {
        endDate = endDate.endOf('day')
      } else {
        endDate = dayjs().subtract(1, 'minutes')
      }
      endDate = endDate.toISOString()
    }

    return {
      ...parameters,
      startDate: parameters.startDate
        ? dayjs(parameters.startDate)
            .startOf('day')
            .toISOString()
        : undefined,
      endDate,
      courierId: this.isPODByCourier ? this.$route.params.courierId : undefined,
    }
  }

  private fetchPODList(resetPage = false): void {
    if (resetPage) this.parameters.page = 1

    this.controller.getPODList(this.formatParams(this.parameters))
  }

  get isExportLoading(): boolean {
    return this.controller.isExportLoading
  }

  private handleExport() {
    const { startDate, endDate, courierId } = this.formatParams(this.parameters)

    const email = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )

    controller.exportPODReport({
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
      courierId: courierId ? Number(courierId) : undefined,
      email,
      cmsUserId: Number(
        LocalStorage.getLocalStorage(LocalStorage.ACCOUNT_ID, true)
      ),
      cmsUserName: email,
    })
  }

  get enablePODExport(): boolean {
    return process.env.VUE_APP_ENABLE_POD_EXPORT === 'true'
  }

  public closeSuccessExportModal(): void {
    this.controller.setSuccessExport(false)
    this.exportSuccessModal = false
  }

  @Watch('controller.isSuccessExport')
  onExportSuccessChanged(val: boolean): void {
    if (val) {
      this.exportSuccessModal = true
    }
  }

  beforeDestroy(): void {
    controller.setSuccessExport(false)
  }
}
